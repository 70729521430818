import * as React from 'react';
import { allExeptLastWord, lastWord, NNU, useSessionStorage } from '../helpers';
import { FormattedDate, useIntl } from 'gatsby-plugin-react-intl';
import Button from '../components/elements/button';
import { Components } from 'react-markdown';
import { EventQuery } from '../../graphqlTypes';
import Form from '../components/elements/formEventVideo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Link from 'gatsby-link';
import Logo from '../components/logo';
import Navbar from '../components/navbar';
import ReactMarkdown from 'react-markdown';
import SEO from '../components/seo';
import SideBar from '../components/sideBar';

import './event.scss';

interface Props {
  data: EventQuery;
}

export const query = graphql`
  query Event($slug: String!) {
    allStrapiEventSection {
      edges {
        node {
          locale
          eventText
          navigateExternalText
          subscribeToEventText
          formButtonText
          subscriptionSuccessMessage
          subscriptionErrorMessage
        }
      }
    }
    allStrapiEventContent(filter: { event: { slug: { eq: $slug } } }) {
      edges {
        node {
          locale
          eventSpeakers {
            speakerDescription
            speakerImage {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 122, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    strapiEvent(slug: { eq: $slug }) {
      strapiId
      eventDate
      contactForm
      eventContents {
        locale
        title
        shortDescription
        longDescription
        place
        externalLink
        tags
      }
      eventImage {
        id
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 392, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

export default function Event({ data }: Props) {
  const intl = useIntl();
  const localeEventSectionData = NNU(data.allStrapiEventSection.edges.find(e => e.node.locale === intl.locale)).node;
  const localeContent = NNU(data.strapiEvent?.eventContents?.find(ec => ec?.locale === intl.locale));
  const localeSpeakersData = NNU(data.allStrapiEventContent.edges.find(e => e.node.locale === intl.locale)).node;

  const [registeredForEvent, setRegisteredForEvent] = useSessionStorage(`registeredForEvent-${NNU(data.strapiEvent?.strapiId)}`, false);
  const [registrationError, setRegistrationError] = React.useState(false);

  const seoImage = data.strapiEvent?.eventImage?.localFile?.publicURL;

  return (
    <Layout
      navBar={layoutScrolledDown => <Navbar scrolled={layoutScrolledDown}>{<h2 className='text-4xl'>{localeEventSectionData.eventText}</h2>}</Navbar>}
      sideBar={() => (
        <SideBar className='sidebar-secondary-page'>
          <Link to='/'>
            <Logo />
          </Link>
        </SideBar>
      )}
    >
      <SEO title={localeContent.title!} description={localeContent.shortDescription!} image={seoImage!} lang={intl.locale} url={localeContent.externalLink!} />
      <div className='container w-screen m-auto event-container'>
        <div className='container-title-cta-image'>
          <div className='container-title-cta-image-left'>
            <h2 className='event-title'>
              {allExeptLastWord(NNU(localeContent.title))} <span className='event-title-last-word'>{lastWord(NNU(localeContent.title))}.</span>
            </h2>
            <h4 className=' event-title-description'>{localeContent.shortDescription}</h4>
            {localeContent.externalLink && (
              <Button className='transition duration-500 ease-in-out transform button-event button-home z-2 hover:-translate-y-1 hover:scale-100 px-12 py-4'>
                <a href={localeContent.externalLink} rel='noreferrer' target='_blank'>
                  {localeEventSectionData.navigateExternalText}
                </a>
              </Button>
            )}
          </div>
          <div className='w-2/5 container-title-cta-image-right'>
            <GatsbyImage
              alt=''
              image={NNU(data.strapiEvent?.eventImage?.localFile?.childImageSharp?.gatsbyImageData)}
              className='shadow-2xl'
              style={{ height: '192px', width: '392px' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
        </div>
        <div className='rounded event-description-container'>
          <div className={'description-event-contain rounded-lg ' + (data.strapiEvent?.contactForm !== false ? 'w-2/4' : 'w-full')}>
            <div className='description-heading'>
              <div className='event-place-date'>
                <h3 className='event-place'>{localeContent.place}</h3>
                <p className='event-date'>
                  <FormattedDate value={new Date(data.strapiEvent?.eventDate)} />
                </p>
              </div>
              <div className='event-tags-container'>
                {localeContent.tags &&
                  localeContent.tags
                    .trim()
                    .split(/\s+/)
                    .map((tag, i) => (
                      <span key={i} className='event-tags'>
                        {tag}
                      </span>
                    ))}
              </div>
            </div>
            <ReactMarkdown className='description'>{localeContent.longDescription || ''}</ReactMarkdown>
            <div className={'event-speakers-container'}>
              {localeSpeakersData.eventSpeakers?.map((es, i) => {
                const components: Components = {
                  // eslint-disable-next-line react/display-name
                  p: ({ children }: { children: React.ReactNode }) => <div className='paragraph'>{children}</div>,
                  // eslint-disable-next-line react/display-name
                  img: () => <GatsbyImage alt='' image={NNU(es?.speakerImage?.localFile?.childImageSharp?.gatsbyImageData)} className='event-speaker-image' />
                };

                return (
                  <ReactMarkdown key={i} className='description' components={components}>
                    {NNU(es?.speakerDescription)}
                  </ReactMarkdown>
                );
              })}
            </div>
            {data.strapiEvent?.contactForm === true ? (
              <div className='event-registration box-content flex flex-col justify-center w-full h-auto'>
                <h3 className='form-title'>{localeEventSectionData.subscribeToEventText}</h3>
                {!registeredForEvent && (
                  <>
                    <Form
                      formID='event'
                      eventID={NNU(data.strapiEvent.strapiId)}
                      actionText={NNU(localeEventSectionData.formButtonText)}
                      contactRegistered={() => setRegisteredForEvent(true)}
                      registrationError={() => setRegistrationError(true)}
                    />
                    {registrationError && <p className='contact-form-error'>{localeEventSectionData.subscriptionErrorMessage}</p>}
                  </>
                )}
                {registeredForEvent && <p>{localeEventSectionData.subscriptionSuccessMessage}</p>}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
}
